import { gql } from "@apollo/client";

export const QuerySettings = () => gql`
  query headlessLocationWpSettings {
    allSettings {
      id
      generalSettingsTitle
      generalSettingsDescription
      wpBoilerplateNodesSettingsReviewsLink
      wpBoilerplateNodesSettingsReviewsMore
      wpBoilerplateNodesSettingsEmbedKey
    }
  }
`;

export const QuerySingle = (fragments) => gql`
  query SingleHook($uri: String!) {
    nodeByUri(uri: $uri) {
      __typename
      ... on Post {
        ...postInfo
      }
      ... on Page {
        ...pageInfo
      }
    }
    acfGroups(uri: $uri) {
      hero {
        id
        background {
          id
          sourceUrl(size: LARGE)
        }
      }
      contactFields {
        id
        phoneNumber
        address
      }
      homeFields {
        id
        videoText
        video {
          id
          mediaItemUrl
        }
      }
    }
  }
  ${fragments.FragmentPostSeo}
  ${fragments.FragmentTaxSeo}
  ${fragments.FragmentCategory}
  ${fragments.FragmentPost}
  ${fragments.FragmentPage}
`;

export const QueryReviews = (fragments) => gql`
  query HomeReviews($endCursor: String) {
    reviews(first: 3, after: $endCursor) {
      edges {
        node {
          id
          title
          content
        }
      }
      pageInfo {
        ...edgePageInfo
      }
    }
  }
  ${fragments.FragmentPageInfo}
`;

export const QueryServices = () => gql`
  query Services {
    services(last: 50) {
      nodes {
        id
        title
        serviceFields {
          price
          per
        }
      }
    }
  }
`;
