import React, { useEffect, useState } from "react";
import { FlatMenu, useMenu } from "react-boilerplate-nodes";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../svg";

const Icon = ({ icon: I, className = "", fill, width = 32 }) => {
  return (
    <div className={`dib br3 bg-white pa1 ${className}`}>
      <span
        className="db br3 overflow-hidden"
        target="_new"
        rel="nofollow noopen"
      >
        <I className={`db fill-${fill}`} {...{ width }} style={{ fill }} />
      </span>
    </div>
  );
};

export const HeroMenu = () => {
  const [menuItems, setMenuItems] = useState();
  const { menuItems: menuItemQuery } = useMenu({ location: "HERO" });

  useEffect(() => {
    if (menuItemQuery.length > 0) {
      setMenuItems((existing) => {
        if (undefined === existing) {
          const newItems = [];

          menuItemQuery.forEach((item) => {
            const _item = {};

            if (item.menuItemField?.icon) {
              switch (item.menuItemField.icon) {
                case "youtube":
                  _item.label = <Icon icon={YoutubeIcon} fill="#FF0000" />;
                  break;
                case "twitter":
                  _item.label = <Icon icon={TwitterIcon} fill="#1DA1F2" />;
                  break;
                case "linkedin":
                  _item.label = <Icon icon={LinkedinIcon} fill="#2867B2" />;
                  break;
                case "facebook":
                  _item.label = <Icon icon={FacebookIcon} fill="#4267B2" />;
                  break;
                case "instagram":
                  _item.label = <Icon icon={InstagramIcon} fill="#8134AF" />;
                  break;
                default:
                  break;
              }
            }

            newItems.push({ ...item, ..._item });
          });

          return newItems;
        }

        return existing;
      });
    }
  }, [menuItemQuery]);

  return (
    <FlatMenu
      location="HERO"
      className="pl0 list inline-flex items-center"
      skullColor="transparent"
      {...{ menuItems }}
    />
  );
};
