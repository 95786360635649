import React from "react";
import { Button as Source } from "react-boilerplate-leadform";

export const Button = ({ className = "", ...props }) => {
  if (!className.includes("bg-")) {
    className += " white bg-primary";
  }

  return (
    <Source
      className={`ttu fw7 ph4 pv3 no-underline pointer ${className}`}
      {...props}
    />
  );
};
