import React, { useMemo } from "react";
import { LeadForm } from "react-boilerplate-leadform";
import { PageWidth, useSingle } from "react-boilerplate-nodes";
import { useSettings } from "../hooks";

export const Contact = ({ children, ...props }) => {
  const { data } = useSingle();
  const { wpBoilerplateNodesSettingsEmbedKey } = useSettings();
  const [phoneNumber, address, addressForMap] = useMemo(() => {
    const ret = [];

    ret.push(data?.acfGroups?.contactFields?.phoneNumber);
    ret.push(data?.acfGroups?.contactFields?.address);
    ret.push(encodeURI(ret[1]));

    return ret;
  }, [data]);

  const showMap = wpBoilerplateNodesSettingsEmbedKey && address;

  return (
    <PageWidth {...props}>
      {children}
      <div className="mv4 flex-ns items-center-ns nl2 nr2">
        <div className="w-50-l ph2">
          <div className="aspect-ratio-ns aspect-ratio--4x3-ns">
            {showMap && (
              <iframe
                className="aspect-ratio--object-ns"
                title="google-map"
                src={`https://www.google.com/maps/embed/v1/place?q=${addressForMap}&key=${wpBoilerplateNodesSettingsEmbedKey}`}
                allowFullScreen
              />
            )}
          </div>
        </div>
        <div className="w-50-l ph2">
          <div>{phoneNumber}</div>
          <div>{address}</div>
        </div>
      </div>
      <div className="mw7 center">
        <LeadForm
          buttonClassName="ttu fw7 ph4 pv3 no-underline pointer white bg-primary w-50-l tc center db"
          buttonLabel="Reach Out"
        />
      </div>
    </PageWidth>
  );
};
