import React, { useMemo } from "react";
import { useSingle, PageWidth } from "react-boilerplate-nodes";

export const Video = () => {
  const { data } = useSingle();
  const [videoText, video] = useMemo(() => {
    const ret = [];

    ret.push(data?.acfGroups?.homeFields?.videoText || null);
    ret.push(data?.acfGroups?.homeFields?.video?.mediaItemUrl || null);

    return ret;
  }, [data]);

  return (
    <div className="bg-light-gray gray">
      <PageWidth>
        <div className="flex-l items-center-l">
          <div className="w-50-l f4 f2-l lh-solid tc ph3">{videoText}</div>
          <div className="w-50-l">
            <div className="aspect-ratio aspect-ratio--1x1">
              <video
                className="aspect-ratio--object"
                src={video}
                autoPlay
                loop
                controls
              />
            </div>
          </div>
        </div>
      </PageWidth>
    </div>
  );
};
