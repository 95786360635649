import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useQueries } from "react-boilerplate-nodes";

export const useSettings = () => {
  const { queries } = useQueries();

  const { data, loading, error } = useQuery(queries.QuerySettings, {
    errorPolicy: "all",
  });

  const ret = useMemo(() => {
    const allSettings = data?.allSettings || {};

    return {
      ...allSettings,
      loading,
      error,
    };
  }, [data, loading, error]);

  return ret;
};
