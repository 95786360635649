import React from "react";
import { PostContent, useSingle } from "react-boilerplate-nodes";
import { Reviews } from "../review";
import { Hero } from "./Hero";
import { Video } from "./Video";

const HeroRender = () => {
  const { data } = useSingle();
  const { sourceUrl } = data?.acfGroups?.hero?.background || {};

  return <Hero className="mb6" background={sourceUrl} />;
};

const Content = () => {
  const { node } = useSingle();
  return (
    <div className="mv6 mw6 center ph3">
      <PostContent>{node.content}</PostContent>
    </div>
  );
};

export const Home = () => {
  return (
    <div>
      <HeroRender />
      <div className="mv6">
        <Reviews />
      </div>
      <div className="mv6">
        <Video />
      </div>
      <Content />
    </div>
  );
};
