// React
import React, { useCallback, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { WordPressRoutes, NodeProvider } from "react-boilerplate-nodes";
import { FormGroup } from "react-boilerplate-leadform";
import { GoogleGTag } from "react-boilerplate-tracking";
import { FRONTEND_URL } from "./config";
import { Header, Footer, Main } from "./layout";
import { Home } from "./home";
import * as queries from "./gql/queries";
import * as fragments from "./gql/fragments";
import "./app.scss";
import { Button, Title } from "./components";
import { AppContext } from "./AppContext";
import { Template } from "./page";

const nodeProps = {
  FRONTEND_URL,
  queries,
  fragments,
  renders: {
    single: {
      Page: Template,
    },
  },
  components: {
    FormGroup,
    Button,
    Title,
  },
};

export const App = () => {
  const [theme, setTheme] = useState({});
  const invertHeader = useCallback((invertedHeader) => {
    setTheme((existing) => ({ ...existing, invertedHeader }));
  }, []);

  return (
    <NodeProvider {...nodeProps}>
      <AppContext.Provider value={{ theme, setTheme, invertHeader }}>
        <GoogleGTag tag="G-Z3RMW3V0XV" />
        <Header />
        <Main>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <WordPressRoutes />
          </Switch>
        </Main>
        <Footer />
      </AppContext.Provider>
    </NodeProvider>
  );
};
