import React, { useContext, useEffect, useState } from "react";
import { PageWidth, Menu } from "react-boilerplate-nodes";
import { Link, Route, Switch } from "react-router-dom";
import { AppContext } from "../AppContext";
import { Button } from "../components";

const HeaderRender = ({ home }) => {
  const { theme, invertHeader } = useContext(AppContext);
  const [open, setOpen] = useState();
  const { invertedHeader: inverted } = theme || {};
  const ImageName = inverted ? "logo-white" : "logo";

  useEffect(() => {
    invertHeader(!!home);
  }, [home, invertHeader]);

  if (inverted === undefined) return null;

  return (
    <header id="header">
      <div
        className={`${home ? "absolute fixed-l top-0 left-0" : "relative"} ${
          inverted ? "" : "bg-white"
        } w-100 z-2 bg-animate tc tl-l pb3 pb0-l`}
      >
        <PageWidth>
          <nav className="flex-l items-center-l w-100">
            <div>
              <div
                className="db dn-l pointer mr3 ma2 absolute top-0 left-0 pl2 pt2"
                onClick={() => setOpen(!open)}
              >
                {Array.from(new Array(3)).map((_, i) => (
                  <div
                    key={`menu-toggle-${i}`}
                    className={`w2 ${
                      inverted ? "bg-near-white" : "bg-near-black"
                    } bg-animate pb1 mt1 mb1`}
                  />
                ))}
              </div>
              <div className="pv2 w-100 w-auto-l">
                <Link to="/" className="no-underline color-inherit">
                  <picture>
                    <source
                      srcSet={`/images/${ImageName}.webp`}
                      type="image/webp"
                    />
                    <img
                      src={`/images/${ImageName}.png`}
                      alt="Logo"
                      width={180}
                    />
                  </picture>
                </Link>
              </div>
            </div>
            <Menu
              onClick={() => setOpen(false)}
              location="HEADER_MENU"
              className={`${open ? "db" : "dn db-l"} ma0 ml4-l f6 ${
                inverted ? "white fw7 fw4-l" : ""
              }`}
            />
            <div className="ml-auto-l">
              <Button to="/contact">Get Started</Button>
            </div>
          </nav>
        </PageWidth>
      </div>
    </header>
  );
};

export const Header = () => {
  return (
    <Switch>
      <Route exact path="/">
        <HeaderRender home />
      </Route>

      <Route>
        <HeaderRender />
      </Route>
    </Switch>
  );
};
