import React from "react";
import { useServices } from "./useServices";

const ServiceItem = ({ serviceFields, title, className = "" }) => {
  const { price, per } = serviceFields || {};

  return (
    <div {...{ className }}>
      <div className="text-shadow-1">{title}</div>
      <div className="self-end ml-auto-ns">
        <div className="dib fw7 f4">${price}</div>
        {per && <div className="dib ml1 f6">per {per}</div>}
      </div>
    </div>
  );
};

export const Services = () => {
  const { services } = useServices();
  return (
    <div className="br3 overflow-hidden">
      {services.map((service) => (
        <ServiceItem
          className="bg-light-gray b--moon-gray bb bw1 pa3 flex-ns items-center-ns"
          key={service.id}
          {...service}
        />
      ))}
    </div>
  );
};
