import React from "react";
import { FlatMenu, PageWidth } from "react-boilerplate-nodes";
import { useSettings } from "../hooks";

const FooterColumn = ({ children, className = "" }) => (
  <div className={`w-100 ${className}`}>{children}</div>
);

export const Footer = () => {
  const { generalSettingsTitle, generalSettingsDescription } = useSettings();

  return (
    <footer id="footer">
      <div className="bg-dark-gray moon-gray">
        <PageWidth>
          <div className="flex-l pv4">
            <FooterColumn>
              <div className="f3 mb2">{generalSettingsTitle}</div>
              <div>{generalSettingsDescription}</div>
            </FooterColumn>
            <FooterColumn className="self-end-l tr-l">
              <nav>
                <FlatMenu
                  location="FOOTER_MENU"
                  skullColor="gray"
                  className="pl0 list mt0 lh-copy f6"
                />
              </nav>
            </FooterColumn>
          </div>
        </PageWidth>
      </div>
      <div className="f7 moon-gray bg-mid-gray pv2">
        <PageWidth>
          <div className="flex-l items-center-l">
            <div>
              Copyright {new Date().getFullYear()}{" "}
              {generalSettingsTitle ? ` - ${generalSettingsTitle}` : "..."}
              <span> | All rights reserved</span>
            </div>

            <div className="ml-auto-l"></div>
          </div>
        </PageWidth>
      </div>
    </footer>
  );
};
