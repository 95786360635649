import React from "react";
import { SingleRender } from "react-boilerplate-nodes";
import { HowItWorks } from "../how";
import { Contact } from "./Contact";

export const Template = ({ node, ...props }) => {
  if (node.pageTemplate === "template-services.php") {
    return <SingleRender wrap={HowItWorks} {...{ node }} {...props} />;
  }

  if (node.title?.includes("ontact")) {
    return <SingleRender wrap={Contact} {...{ node }} {...props} />;
  }

  return <SingleRender {...{ node }} {...props} />;
};
