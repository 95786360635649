import React, { useEffect } from "react";
import { PostContent } from "react-boilerplate-nodes";
import { useSettings } from "../hooks";
import { StarIcon } from "../svg";
import { useReviews } from "./useReviews";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const ReviewCard = ({ title, content, className = "" }) => {
  return (
    <div {...{ className }}>
      <div>
        <PostContent className="f6">{content}</PostContent>
        <div className="tc mv2">
          {Array.from(new Array(5)).map((_, i) => (
            <StarIcon
              key={`star-${i}`}
              width={16}
              className="fill-yellow mr2"
            />
          ))}
        </div>
        <div className="fw7 primary mt2 tc">{title}</div>
      </div>
    </div>
  );
};

const Slideshow = ({ edges, loadMore }) => {
  useEffect(() => {
    let timeout;

    if (edges.length > 0) {
      timeout = setTimeout(() => {
        loadMore();
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [edges, loadMore]);

  return (
    <div className="w-100 overflow-hidden relative z-1">
      <div className="absolute absolute--fill z-1 flex items-stretch">
        <div className="flex-auto w-100 gradient-1" />
        <div className="mw7 w-100 flex-none" />
        <div className="flex-auto w-100 gradient-2" />
      </div>
      <div className="mw7 center ph3">
        <Slide pauseOnHover={false}>
          {edges.map(({ node }) => (
            <ReviewCard
              key={node.id}
              className="h-100 flex items-center ph5"
              {...node}
            />
          ))}
        </Slide>
      </div>
    </div>
  );
};

const TextWithRule = ({
  color = "primary",
  wrap: Wrap = "div",
  children,
  ...props
}) => {
  return (
    <React.Fragment>
      <div>
        <Wrap className={`ttu ${color} no-underline fw7`} {...props}>
          {children}
        </Wrap>
      </div>
      <Rule {...{ color }} />
    </React.Fragment>
  );
};

const Rule = ({ color = "primary" }) => {
  return <div className={`mw2 b--${color} bb bw1 center mt3`} />;
};

const ReviewLink = ({ href, children }) => {
  if (!href) return null;

  return (
    <div className="pa3 lh-solid">
      <TextWithRule {...{ href }} wrap="a">
        {children}
      </TextWithRule>
    </div>
  );
};

export const Reviews = ({ className = "" }) => {
  const { edges, loadMore } = useReviews();

  const {
    wpBoilerplateNodesSettingsReviewsLink,
    wpBoilerplateNodesSettingsReviewsMore,
  } = useSettings();

  if (edges.length === 0) {
    return null;
  }

  return (
    <div {...{ className }}>
      <div className="tc mb4">
        <TextWithRule color="dark-gray">Testimonials</TextWithRule>
      </div>
      <Slideshow {...{ edges, loadMore }} />

      <div className="flex-ns items-center-ns justify-center-ns tc mt4">
        <ReviewLink href={wpBoilerplateNodesSettingsReviewsLink}>
          Write a Review
        </ReviewLink>
        <ReviewLink href={wpBoilerplateNodesSettingsReviewsMore}>
          More Reviews
        </ReviewLink>
      </div>
    </div>
  );
};
