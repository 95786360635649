import React, { forwardRef } from "react";
import { PageWidth, SkullWord } from "react-boilerplate-nodes";

let Title = (
  { notHeading, wrap = "h1", className = "", children, ...props },
  ref
) => {
  const Wrap = notHeading ? "div" : wrap;
  return (
    <div className={`pv4 ${className}`} {...props}>
      <PageWidth>
        <Wrap className="title ma0 lh-title tc">
          <span className="f2 fw7 db" {...{ ref }}>
            {children ? children : <SkullWord className="w-100 mw6" />}
          </span>
        </Wrap>
        <div className="b--moon-gray bb bw1 mt4" />
      </PageWidth>
    </div>
  );
};

Title = forwardRef(Title);

export { Title };
