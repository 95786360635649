import { useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { getPageInfo, useQueries } from "react-boilerplate-nodes";

export const useReviews = () => {
  const { queries } = useQueries();

  const { fetchMore, data, loading, error } = useQuery(queries.QueryReviews, {
    errorPolicy: "all",
  });

  const edges = useMemo(
    () => (data?.reviews?.edges?.length > 0 ? data?.reviews?.edges : []),
    [data]
  );

  const loadMore = useCallback(() => {
    const { endCursor, hasNextPage } = getPageInfo(
      data?.reviews?.pageInfo || {}
    );

    if (hasNextPage) {
      fetchMore({ variables: { endCursor } });
    }
  }, [fetchMore, data]);

  return {
    loadMore,
    edges,
    loading,
    error,
  };
};
