import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useQueries } from "react-boilerplate-nodes";

export const useServices = () => {
  const { queries } = useQueries();

  const { data, loading, error } = useQuery(queries.QueryServices, {
    errorPolicy: "all",
  });

  const services = useMemo(
    () => (data?.services?.nodes?.length > 0 ? data?.services?.nodes : []),
    [data]
  );

  return {
    services,
    loading,
    error,
  };
};
