import React from "react";
import { PageWidth } from "react-boilerplate-nodes";
import { ServiceForm } from "./ServiceForm";
import { Services } from "./Services";

export const HowItWorks = ({ children, ...props }) => {
  return (
    <PageWidth {...props}>
      <div>{children}</div>
      <div className="mv4">
        <Services />
      </div>
      <div className="mt4">
        <ServiceForm />
      </div>
    </PageWidth>
  );
};
