import { gql } from "@apollo/client";
import { disableFragmentWarnings } from "graphql-tag";

disableFragmentWarnings();

export const FragmentMenuItem = gql`
  fragment menuItemInfo on MenuItem {
    id
    databaseId
    parentId
    url
    label
    cssClasses
    menuItemField {
      icon
    }
    connectedNode {
      node {
        __typename
      }
    }
  }
`;
