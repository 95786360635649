import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../components";
import {
  useRecaptcha,
  FormGroup,
  isEmail,
  isPhone,
  useFormData,
  useLeadFormMutation,
  useForm,
} from "react-boilerplate-leadform";
import { useServices } from "./useServices";
import { gql } from "@apollo/client";

const mutation = gql`
  mutation ServiceFormMutation(
    $clientMutationId: String!
    $wpNonce: String!
    $gToken: String
    $yourName: String
    $email: String!
    $phone: String!
    $service: String
    $message: String
  ) {
    serviceFormMutation(
      input: {
        clientMutationId: $clientMutationId
        wpNonce: $wpNonce
        gToken: $gToken
        yourName: $yourName
        email: $email
        phone: $phone
        service: $service
        message: $message
      }
    ) {
      clientMutationId
      success
      errorMessage
    }
  }
`;

const schema = {
  yourName: {
    valid: function (v) {
      return v !== "";
    },
    text: "You must include a name.",
  },
  email: {
    valid: function (v) {
      return isEmail(v);
    },
    text: "You must include a email.",
  },
  phone: {
    valid: function (v) {
      return isPhone(v);
    },
    text: "Invalid phone.",
  },
  service: {
    valid: function () {
      return true;
    },
  },
  message: {
    valid: function () {
      return true;
    },
  },
};

export const ServiceForm = ({
  buttonClassName,
  submitionMessage = "Form submitted. Thank you for your submission.",
  buttonLabel = "Submit",
  loading: loadingProp,
}) => {
  const { services } = useServices();
  const [form, setForm] = useState({});
  const [message, setMessage] = useState();
  const { recaptchaSiteKey, nonce } = useFormData("service");
  const [completed, setCompleted] = useState(false);
  const { token } = useRecaptcha({
    trigger: isEmail(form.email),
    key: recaptchaSiteKey,
  });

  const serviceOptions = useMemo(() => {
    const options = [];

    services.forEach((item) => {
      const { price, per } = item.serviceFields || {};

      options.push({
        value: item.title,
        label: `${item.title} - $${price} ${per ? `per ${per}` : ""}`,
      });
    });

    return options;
  }, [services]);

  useEffect(() => {
    if (form.service === undefined && serviceOptions.length > 0) {
      setForm((prev) => ({ ...prev, service: serviceOptions[0].value }));
    }
  }, [form, serviceOptions]);

  const { submitted, loading: mutateLoading } = useLeadFormMutation({
    token,
    nonce,
    mutation,
    onCompleted: (data = {}) => {
      console.log(data);
      const { serviceFormMutation = {} } = data || {};
      const { errorMessage, success } = serviceFormMutation || {};

      if (success) {
        setCompleted(true);
      } else {
        setMessage(errorMessage);
      }
    },
  });

  const loading = mutateLoading || loadingProp;

  const { Check, onChange, onError } = useForm({
    form,
    setForm,
    schema,
    submitted,
  });

  return (
    <div className="mw7 center">
      <div className="cf">
        {completed && (
          <div className="success-message primary fw7 f6 mb3">
            {submitionMessage}
          </div>
        )}
        {message && (
          <div className="error-message red fw7 f6 mb3">{message}</div>
        )}
        <FormGroup
          label="Your Name"
          id="yourName"
          value={form.yourName}
          onChange={(value) => onChange(value, "yourName")}
          help={onError("yourName")}
        />
        <div className="nl2 nr2">
          <FormGroup
            type="email"
            className={`w-50-l fl-l ph2`}
            label="Your Email"
            id="email"
            value={form.email}
            onChange={(value) => onChange(value, "email")}
            help={onError("email")}
          />
          <FormGroup
            type="tel"
            className={`w-50-l fl-l ph2`}
            label="Your Phone"
            id="phone"
            value={form.phone}
            onChange={(value) => onChange(value, "phone")}
            help={onError("phone")}
          />
        </div>
        <FormGroup
          type="select"
          label="Service"
          id="service"
          value={form.service}
          options={serviceOptions}
          onChange={(value) => onChange(value, "service")}
          help={onError("service")}
        />
        <FormGroup
          label="Message"
          type="textarea"
          id="message"
          value={form.message}
          onChange={(value) => onChange(value, "message")}
          help={onError("message")}
        />
      </div>
      <div className={`button-wrap tr`} style={{ marginBottom: 0 }}>
        <Button
          {...{ loading }}
          className={buttonClassName}
          onClick={() => Check()}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};
