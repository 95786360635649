import React, { useContext, useEffect, useRef } from "react";
import { PageWidth, scrollHandler } from "react-boilerplate-nodes";
import { useLocation } from "react-router-dom";
import { AppContext } from "../AppContext";
import { HeroMenu } from "./HeroMenu";

export const Hero = ({ className = "", background }) => {
  const heroElement = useRef();
  const { invertHeader } = useContext(AppContext);
  const { pathname } = useLocation();
  const props = {};

  useEffect(() => {
    const SwitchHeader = ({ y }) => {
      if (heroElement) {
        const height = heroElement.current.clientHeight;

        if (y > height) {
          invertHeader(false);
        } else if (pathname === "/") {
          invertHeader(true);
        }
      }
    };

    scrollHandler.add(SwitchHeader);

    return () => scrollHandler.remove(SwitchHeader);
  }, [heroElement, invertHeader, pathname]);

  if (background) {
    props.style = { backgroundImage: `url("${background}")` };
  }

  return (
    <div
      ref={heroElement}
      className={`vh-100 bg-center cover relative z-1 ${className}`}
      {...props}
    >
      <div className="absolute z-1 bottom-0 left-0 w-100 pv4">
        <PageWidth>
          <div className="tc tr-l">
            <HeroMenu />
          </div>
        </PageWidth>
      </div>
    </div>
  );
};
